
.h-screen {
  height: 100vh;
  width: 100vw;
  .logo {
    height: 32px;
    margin: 16px 16px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #fff;
  }
  .h-full {
    height: 100%;
    padding: 15px;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
}
